//import { useBase } from '@/store'

// 密码校验（6-20位数字和字母）
export function passwordRegExp(value) {
	let reg = new RegExp('^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$')
	return reg.test(value)
}
// 获取字典转格式
//export const getDictList = (
//    dictName,
//    fieldNames = { label: 'label', enLabel: 'enLabel', value: 'value' }
//) => {
//    const Base = useBase()
//    let list = Base.dictList?.get(dictName) // dictLabel, dictValue
//    // debugger
//    if (fieldNames) {
//        let { label, enLabel, value } = fieldNames
//        list = list.map((dict) => {
//            return {
//                [label]: dict.dictLabel,
//                [enLabel]: dict.dictEnLabel,
//                [value]: dict.dictValue,
//            }
//        })
//        //debugger
//    }
//    return list
//}
// 获取label值
//export const getDictLabel = (dictName, value) => {
//    if (!value) return ''
//    let langStore = getSessionData('langStore')
//    console.log(langStore)
//    let stringValue = String(value)
//    let dictItem = getDictList(dictName).find(
//        (item) => item.value === stringValue
//    )
//    if (dictItem)
//        return dictItem[langStore?.lang === 'en-US' ? 'enLabel' : 'label']
//    return ''
//}
export function setSessionData(name, value) {
	if (!value) {
		return
	}
	if (typeof value !== 'string') {
		value = JSON.stringify(value)
	}
	window.sessionStorage.setItem(name, value)
}
export function setlocalData(name, value) {
	if (!value) {
		return
	}
	if (typeof value !== 'string') {
		value = JSON.stringify(value)
	}
	window.localStorage.setItem(name, value)
}
export function getSessionData(name) {
	if (!name) {
		return
	}
	let data = window.sessionStorage.getItem(name)
	try {
		data = JSON.parse(data)
	} catch (e) {
		//TODO handle the exception
	}

	return data
}
export function getlocalData(name, value) {
	if (!name) {
		return
	}
	let data = window.localStorage.getItem(name)
	try {
		data = JSON.parse(data)
	} catch (e) {
		//TODO handle the exception
	}

	return data
}
export function removeSessionData(name) {
	if (!name) {
		return
	}
	window.sessionStorage.removeItem(name)
}
export function removelocalData(name) {
	if (!name) {
		return
	}
	window.localStorage.removeItem(name)
}

// 解决遮罩层滚动穿透问题，分别在遮罩层弹出后和关闭前调用
let _scrollTop
export class ModalHelper {
	// popup 显示时调用
	static afterOpen() {
		_scrollTop = document.scrollingElement.scrollTop
		document.body.style.position = 'fixed'
		document.body.style.top = -_scrollTop + 'px'
	}

	// popup 关闭时调用
	static beforeClose() {
		document.body.style.position = ''
		document.body.style.top = ''
		// 使 scrollTop 恢复原状
		document.scrollingElement.scrollTop = _scrollTop
	}
}
