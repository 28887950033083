import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const zh = {
	menus: {
		WCAH: 'WCAH大会',
		ZUEL: '中南财经政法大学',
		COMMITTEES: '组织机构',
		'CONFERENCE-PROGRAM': '会议日程',
		'CALL-FOR-PAPERS': '征稿启事',
		'VISA-AND-TRAVEL': '旅行和住宿',
		CONTACTS: '联系人',
		TouristAttractions: '武汉景点',
		Route1: '线路 No.1: 楚文化探源—武汉市内游及夜游长江',
		Route2: '路线 No.2: 三峡大坝、三峡人家二日游',
		Route3: '路线 No.3: 西安古都文化游',
		Route4: '路线 No.4',
		TouristDirections: '交通指引',
		JiHotel: '全季酒店',
		HarvestInternationalHotel: '金谷国际酒店',
		UniversityConstitution: '学校简介',
		CALLFORPAPERS: '征稿通知',
		VISAANDTRAVEL: '旅行和住宿',
		CONTACTS: '联系我们',
		HotelInformation: '酒店信息',
		TouristRoutes: '旅游路线',
		loading: '加载中...',
		Partners: 'WCAH 2024合作伙伴',
		WCAHTitle: '新闻与公告',
		ThematicSpeaker: '主题发言人',
		CongressOrganization: '组委会',
		Accounting: '中国会计学会',
		Academy: '国际会计史学家学会',
		Management: '管理世界',
		Economic: '中国财政经济出版社',
		Shanghai: '上海立信会计金融学院',
		DownloadApp: '下载APP',
		Registration: '会议注册及费用',
		newsDesc: '新闻详情',
	},
	form: {
		login: '登录',
		register: '注册',
		'User-login': '用户登录',
		'User-login-tips':
			'请登录您的帐户以访问后台,您的登录账号就是注册时使用的邮箱',
		'account-placeholder': '请输入账号',
		'account-tips': '请输入账号',
		'password-placeholder': '请输入密码',
		'password-tips': '请输入6-20位密码',
		'log-in': '登录',
		'Forget-password-tips': '忘记密码',
		'Create-account-tips': `还没有注册帐户吗？创建帐户`,
		'Password-reset': '密码重置',
		'Password-reset-tips': '在下面输入您的电子邮件重置密码。',
		'Please-bind-email': '请绑定电子邮件',
		'Please-bind-email-tips': '请输入合法邮箱',
		'send-code': '发送验证码',
		'bind-code-placeholder': '请绑定验证码',
		'bind-code-tips': '请输入验证码',
		'Reset-password': '重置密码',
		'Go-log-on': '去登录',
		'confirm-password': '请确认密码',
		'Go-to-login': '去登录',
		'Create-account': '创建帐户',
		'Create-account-Welcome': '欢迎注册新账户...',
		registration: '欢迎注册',
		'registration-tips': '请输入您的真实姓名',
		'password-again': '请重新输入密码',
		inconsistent: '这两个密码不一致！',
		'Agree-to': '同意',
		'Service-Agreement': '服务协议',
		'Privacy-policy': '用户注册协议',
		'Create-my-account': '创建我的帐户',
		'Login-now': '已经有账户了吗？立即登录',
		'old-password-placeholder': '请输入您的旧密码',
		'new-password-placeholder': '请输入您的新密码',
		'new-password-agin': '请确认新密码',
		'email-placeholder': '请输入邮箱地址',
		'Country-placeholder': '请选择国家/地区',
	},
	center: {
		personalData: '个人资料',
		PasswordChange: '密码修改',
		messageNotification: '消息通知',
		paperUploading: '论文上传',
		Paper: '论文上传',
		account: '账号',
		phone: '手机',
		realName: '真实姓名',
		gender: '性别',
		Country: '国家/地区',
		unit: '单位',
		identity: '身份',
		identity01: '作者',
		identity02: '在读博士',
		identity03: '随行人员',
		'identity-placeholder': '请选择身份',
		'phone-placeholder': '请输入手机号码',
		'unit-placeholder': '请输入单位',
		SaveModify: '保存和修改',
		enclosure: '附件:',
		UploadDate: '上传日期:',
		thesisTitle: '论文标题',
		paperPlaceholder: '请输入论文标题',
		ClickUpload: '点击上传',
		uploadTips: `只能上传一个PDF/DOCX/DOC文件，并且不应超过500MB`,
		email: '邮箱',
		'gender-placeholder': '请选择性别',
		Classified: '保密',
		Man: '男',
		Woman: '女',
		'pdf-tips-size': '文件大小超出500MB',
		'pdf-tips-type': '文件格式不为pdf/DOCX/DOC',
		cancel: '取消',
		upload: '上传',
		'pdf-tips': '上传文件为空',
		'title-tips': '上传标题为空',
		'pdf-tips-file': '上传文件失败',
		confirm: '确定',
		cancel: '取消',
		'link-tips': '上传文件请移步电脑端网站',
		registrationPayment: '报名提交',
		paymentHistory: '支付订单',
		myRegistration: '我的报名',
	},
	pay: {
		destination: '目的地',
		city: '武汉',
		checkIn: '入住',
		checkOut: '退房',
		nights: '晚',
		checkInTimeTips: '开始日期',
		checkOutTimeTips: '结束日期',
		title1: '酒店选择',
		title2: '酒店入住信息',
		hotelIn: '入住酒店',
		dayIn: '入住天数',
		checkInTime: '入住时间',
		checkOutTime: '退房时间',
		hotelMoney: '酒店费用',
		title3: '报名费用',
		ConferenceName: '大会名称',
		identity: '账号身份',
		payNumber: '人数',
		RegistrationFee: '报名费用(人民币)',
		title4: '请选择支付方式',
		toPay: '去支付',
		payStore: '到店付',
		confirmPay: '确认已支付',
		payType: '支付方式',
		AliPay: '支付宝',
		WeChat: '微信',
		'third-party': '第三方支付',
		QR: '刷新二维码',
		QRExpire: '二维码已过期',
		paySuccess: '支付成功',
		payAmount: '支付金额',
		back: '返回',
		payError: '支付失败',
		tips1: '距离订单过期还剩',
		tips2: '秒，请尽快完成支付',
		tips3: '已扫码，等待支付中',
		tips4: '请使用支付宝扫一扫',
		tips5: '扫描二维码支付',
		tips6: '请使用微信扫一扫',
		RegistrationFee: '报名费用(人民币)',
		title4: '请选择支付方式',
		toPay: '去支付',
		payStore: '到店付',
		oderNum: '订单号',
		WaitingPayment: '等待支付',
		payStatusT: '已付款',
		payStatusF: '支付失败',
		payTime: '支付时间',
		createOrderTime: '创建时间',
		Registered: '已报名',
		address: '报名地址',
		organizer: '主办单位',
		registerTime: '报名时间',
		CreateOrder: '创建订单',
		confirmPayCompleted: '支付完成',
		removeOrder: '取消订单',
		payStatusCancel: '订单已取消',
		notes: '备注',
		position: 'left',
		invoicingBtn: '申请开票',
		invoicingPreviewBtn: '发票预览',
		dialogTitle: '申请开票',
		payerType: '开票类型',
		payerType0: '个人/非企业',
		payerType1: '企业单位',
		tin: '纳税人识别号',
		payerName: '缴费人姓名',
		orderNum: '证件号',
		invoiceTitle: '发票抬头',
		invoicePhone: '手机',
		invoiceEmail: '邮箱',
		dialogbtn1: '取消',
		dialogbtn2: '提交',
		tinMsg: '请填写纳税人识别号',
		payerNameMsg: '请填写缴费人姓名',
		orderNumMsg: '请填写证件号',
		invoiceTitleMsg: '请填写发票抬头',
		invoicePhoneMsg: '请填写手机号',
		invoiceEmailMsg: '请填写邮箱',
	},
	message: {
		hello: '你好',
	},
	...zhLocale,
}
//组织结构 会议日程 征稿启事 旅行和住宿 联系人
export default zh
