import axios from 'axios'
import { Message } from 'element-ui'
import Store from '@/store'

import {
	getSessionData,
	removeSessionData,
	getlocalData,
	removelocalData,
} from '@/common/common'

let BASEURL = ''
// 环境的切换
if (process.env.NODE_ENV === 'development') {
	// 开发环境
	BASEURL = YB_BASE.REQUEST_URL
	// BASEURL = '/api'
}
if (process.env.NODE_ENV === 'production') {
	// 正式环境
	BASEURL = process.env.VUE_APP_BASE_URL
}

const instance = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API,
	// baseURL: import.meta.env.VITE_APP_HTTP_REQUEST,
	baseURL: BASEURL,
	timeout: 600000,
})
instance.defaults.headers.post['Content-Type'] =
	'application/json;charset=UTF-8' //配置请求头
//instance.defaults.headers["Accept-Language"] = lang.value; //配置请求头国际化

instance.interceptors.request.use(
	(config) => {
		//debugger;
		// console.log('lang', lang.value)
		let langStore = getSessionData('langStore')
		config.headers['Accept-Language'] = langStore?.lang ?? 'zh-CN'
		// 在发送请求之前做些什么
		if (!config.headers.isUpload) {
			let token = getlocalData('authorization')
			// 只要有token就自己带上
			if (token) {
				// config.headers[YB_BASE.TOKEN_NAME] = token
				config.headers[process.env.VUE_APP_TOKEN_NAME] = token
			}
		}
		return config
	},
	(error) => {
		//debugger;
		// 对请求错误做些什么
		return Promise.reject(error)
	}
)

let IsExpired = false
// 添加响应拦截器
instance.interceptors.response.use(
	(res) => {
		console.log('环境配置', process.env)
		//debugger;
		// 针对特殊接口返回做处理
		if (res.headers['request-type'] !== undefined) {
			// 如果是导出excel
			if (res.headers['request-type'] === 'download') {
				return res
			}
		}
		// 对响应数据做点什么
		// if (YB_BASE.SUCCESS_CODE.includes(res.data.code)) {
		if (process.env.VUE_APP_SUCCESS_CODE.includes(res.data.code)) {
			console.log(res.config.headers)
			//debugger
			if (res.data.msg && res.config.headers['hide-msg'] !== 1) {
				//Message.success(res.data.msg)
				Message({
					type: 'success',
					message: res.data.msg,
					center: true,
				})
			}
			return res.data
		} else {
			// token过期
			// if (YB_BASE.TOKEN_EXPIRE.includes(res.data.code)) {
			if (process.env.VUE_APP_TOKEN_EXPIRE.includes(res.data.code)) {
				// debugger
				if (IsExpired) return Promise.reject(false)
				IsExpired = true
				Message.error({
					// message: res.data.msg ? res.data.msg : '服务器异常，请稍后再试',
					message: '登录已过期...',
					onClose: () => {
						removeSessionData('userInfo')
						// removeSessionData(YB_BASE.TOKEN_NAME)
						removelocalData(process.env.VUE_APP_TOKEN_NAME)
						IsExpired = false
						Store.commit('setToken', {
							token: null,
							userId: null,
						})
						Store.commit('setName', null)
						Store.commit('setMail', null)
						location.href = ''
						//location.reload()
					},
				})
				// } else if (YB_BASE.ERROR_CODE.includes(res.data.code)) {
			} else if (process.env.VUE_APP_ERROR_CODE.includes(res.data.code)) {
				// 错误码
				Message.error(res.data.msg ? res.data.msg : '服务器异常，请稍后再试')
				return Promise.reject(res.data)
			}
		}
	},
	(error) => {
		// 对响应错误做点什么
		// token过期
		console.log('axios-error:', error)
		// if (YB_BASE.TOKEN_EXPIRE.includes(error.response.data.code)) {
		if (process.env.VUE_APP_TOKEN_EXPIRE.includes(error.response.data.code)) {
			// debugger
			if (IsExpired) return false
			IsExpired = true
			Message.error({
				message: error.response.data.msg
					? error.response.data.msg
					: '服务器异常，请稍后再试',
				onClose: () => {
					removeSessionData('userInfo')
					// removeSessionData(YB_BASE.TOKEN_NAME)
					removelocalData(process.env.VUE_APP_TOKEN_NAME)
					IsExpired = false
					location.reload()
				},
			})
		}
		// if (YB_BASE.ERROR_CODE.includes(error.response.data.code)) {
		if (process.env.VUE_APP_ERROR_CODE.includes(error.response.data.code)) {
			Message.error(
				error.response.data.msg
					? error.response.data.msg
					: '服务器异常，请稍后再试'
			)
		}
		Message.error(error.response.status + ' - ' + error.response.statusText)
		return Promise.reject(error)
	}
)

export const post = (url, params = {}, headers = {}) => {
	return instance.post(url, params, { headers })
}
export const get = (url, params = {}) => {
	return instance.get(url, { params })
}
export const download = (url, params = {}) => {
	return new Promise((resolve, reject) => {
		//debugger
		instance
			.get(url, {
				timeout: 3 * 60 * 1000,
				params: params,
				responseType: 'blob',
			})
			.then(
				(res) => {
					let fileName
					let reader = new FileReader()
					let data = res.data
					reader.onload = (e) => {
						console.log(res.headers)
						if (
							[e.target.result].indexOf('Result') != -1 &&
							// @ts-ignore
							JSON.parse(e.target.result).Result == false
						) {
							// 进行错误处理
						} else {
							//if (!fileName) {
							//    let contentDisposition = res.headers['content-disposition']
							//    // debugger;
							//    if (contentDisposition) {
							//        fileName = window.decodeURI(
							//            res.headers['content-disposition'].split('=')[1],
							//            // @ts-ignore
							//            'UTF-8'
							//        )
							//    }
							//}
							fileName = decodeURIComponent(res.headers['content-disposition'])
							console.log(fileName)
							executeDownload(data, fileName)
						}
					}
					reader.readAsText(data)
					resolve(res.data)
				},
				(err) => {
					reject(err)
				}
			)
			.catch((error) => {
				reject(error)
			})
	})
}

//  模拟点击a 标签进行下载
function executeDownload(data, fileName) {
	if (!data) {
		return
	}
	let url = window.URL.createObjectURL(new Blob([data]))
	let link = document.createElement('a')
	link.style.display = 'none'
	link.href = url
	link.setAttribute('download', fileName)
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}
