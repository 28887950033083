import { get, post, download } from './axios.js'

const ApiRequests = {
	/************ 登录 注册 ************** */
	login: (params) => post('/login', params), //登录
	sendEmailCode: (params) => post('/system/member/sendEmailCode', params), //发送邮箱验证码
	createAccount: (params) => post('/system/member/createAccount', params), //新增会员
	sendEmail4ResetPwd: (params) =>
		post('/system/member/sendEmail4ResetPwd', params), //发送重置密码的邮件
	checkPwdReset: (params) => post('/system/member/checkPwdReset', params), //校验密码重置
	resetPwd: (params) => post('/system/member/resetPwd', params), //重置密码
	upload: (params) => post('/common/upload2', params), //上传
	getUserInfo: (params) =>
		post('/system/member/getUserInfo', params, { 'hide-msg': 1 }), //获取会员管理详细信息
	editUserInfo: (params) => post('/system/member/editMember', params), //修改会员管理详细信息
	addThesis: (params) => post('/system/thesis/addThesis', params), //新增论文
	editMemberPassword: (params) =>
		post('/system/member/editMemberPassword', params), //修改会员密码
	getPaymemtHistorys: (params) =>
		post('/system/record/getPaymemtHistorys', params, { 'hide-msg': 1 }), //获取登录用户支付历史记录
	getTimeByUserId: (params) =>
		post('/system/enroll/getTimeByUserId', params, { 'hide-msg': 1 }), //报名记录
	getMessages: (params) =>
		post('/system/message/getMessages', params, { 'hide-msg': 1 }), //消息记录
	getThesis: (params) =>
		post('/system/thesis/getList', params, { 'hide-msg': 1 }), //论文列表
	getEnrollPayByUserId: (params) =>
		post('/system/hotel/getEnrollPayByUserId', params, { 'hide-msg': 1 }), //查询酒店列表以及报名信息
	getNewsByCategory: (params) =>
		post('/system/news/getNewsByCategory', params, { 'hide-msg': 1 }), //根据新闻分类查询新闻
	getNewsByNewsCategorys: (params) =>
		post('/system/news/getNewsByNewsCategorys', params, { 'hide-msg': 1 }), //查询新闻列表
	getNewsByNewsDesc: (params) =>
		post('/system/news/queryById', params, { 'hide-msg': 1 }), //根据ID查询新闻详情
	getImgList: (params) =>
		post('/system/rotationChart/getList', params, { 'hide-msg': 1 }), //查询轮播列表

	/****************  ZFB  ****************************** */
	tradePreCreatePay: (params) =>
		post('/aliPay/tradePreCreatePay', params, { 'hide-msg': 1 }), //支付
	tradeQuery: (params) => post('/aliPay/tradeQuery', params, { 'hide-msg': 1 }), //支付宝-查询交易
	tradeQueryNotify: (params) =>
		post('/aliPay/cert_notify_url', params, { 'hide-msg': 1 }), //支付宝-回调并修改支付状态

	downloadFile: (params) => download('/common/downloads', params), //下载

	//支付流程发生变化，现在流程为： 创建订单，去订单页根据预选的支付方式跳转详情页扫码支付
	addBizOrderInfo: (params) => post('/bizOrderInfo/addBizOrderInfo', params), //创建订单
	getBizOrderInfos: (params) =>
		post('/bizOrderInfo/getBizOrderInfos', params, { 'hide-msg': 1 }), //查询订单列表
	getBizOrderInfoById: (params) =>
		post('/bizOrderInfo/getBizOrderInfoById', params, { 'hide-msg': 1 }), //根据ID查询订单详情
	nativePayWx: (params) =>
		post('/bizOrderInfo/nativePay', params, { 'hide-msg': 1 }), //微信支付生成二维码
	tradeQuery: (params) =>
		post('/bizOrderInfo/tradeQuery', params, { 'hide-msg': 1 }), //轮询支付详情
	cancelOrder: (params) =>
		post('/bizOrderInfo/cancelOrder', params, { 'hide-msg': 1 }), //取消订单
	applyForInvoicing: (params) =>
		post('bizOrderInfo/applyForInvoicing', params, { 'hide-msg': 1 }), //申请发票
	invoiceQuery: (params) =>
		post('bizOrderInfo/invoiceQuery', params, { 'hide-msg': 1 }), //查询发票

	//新增的第三方支付，需要把微信，支付宝支付取消，统一用第三方的
	nativePay: (params) =>
		post('/bizOrderInfo/nativePay', params, { 'hide-msg': 1 }), //网页支付
}

const HttpProxy = new Proxy(
	async ({ type, result, onError }) => {
		if (type === 'success') {
			//console.log('result的结构是', result)
			//debugger
			// 接口成功
			console.log('------------------', result)
			// result的结构是 { code: 200, data: {}, msg: ''}
			if (result.code !== undefined && result.data !== undefined) {
				return result.data
			} else {
				// 有一些上传返回数据格式直接是二进制，没有结构，直接返回result
				return result
			}
		} else {
			// 接口error
			// if (YB_BASE.ERROR_CODE.includes(result.code)) {
			console.log(import.meta.env)
			if (process.env.VUE_APP_ERROR_CODE.includes(result.code)) {
				// 500错误处理函数
				if (onError) {
					onError(result)
				}
				return Promise.reject(result)
			}
		}
	},
	{
		async apply(target, object, args) {
			let { apiName, params, onError } = args[0]
			try {
				let ret = await ApiRequests[apiName](params)
				// debugger
				return target({ type: 'success', result: ret })
			} catch (error) {
				console.log('httpProxy-error:', error)
				return target({ type: 'error', result: error, onError })
			}
		},
	}
)

export default HttpProxy
