import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './lang'



Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: sessionStorage.getItem('lang') || 'en',//F5刷新国际化保持缓存选择
    messages,
    silentTranslationWarn: true,//去掉国际化警告
})
export default i18n