<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	created() {
		let flag = navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		)
		console.log('flag', flag)
		if (flag) {
			window.location.href = 'https://wcah2024.org.cn/account_h5/#/'
		}
	},
}
</script>

<style lang="scss">
@import '../src/assets/styles/pageStyle';
.shan {
	position: fixed;
	bottom: 0;
	width: 100vw;
	height: 509px;
	background: url('@/assets/image/shan.png') no-repeat;
	background-size: 100% 100%;
	z-index: -999;
}
.width1100 {
	width: 1100px;
	justify-content: center;
	margin: 0 auto;
}
</style>
