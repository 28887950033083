import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        token: '',
        userId: '',
        realName: null,
        mail: '',
        optionActive: 0
    },
    getters: {},
    mutations: {
        setToken (state, obj) {
            (state.token = obj.token), (state.userId = obj.userId)
        },
        setName (state, name) {
            state.realName = name
        },
        setMail (state, name) {
            state.mail = name
        },
        setOptionActive (state, name) {
            state.optionActive = name
        },
    },
    actions: {},
    modules: {},
    plugins: [
        createPersistedstate({
            key: 'userInfo', // 存数据的key名   自定义的  要有语义化
            // paths: ['loginModule'] // 要把那些模块加入缓存
        }),
    ],
})
