import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
	menus: {
		WCAH: 'WCAH',
		ZUEL: 'ZUEL',
		COMMITTEES: 'Committees',
		'CONFERENCE-PROGRAM': 'Conference Program',
		'CALL-FOR-PAPERS': 'Call For Papers',
		'VISA-AND-TRAVEL': 'Travel And Accommodation',
		CONTACTS: 'Contacts',
		TouristAttractions: 'Wuhan Attractions',
		Route1:
			'Route No.1: Exploring the Origin of Chu Culture - Wuhan City Inner Tour and Night Tour of the Yangtze River',
		Route2: 'Route No.2: Three Gorges Dam and Three Gorges Family 2-day Tour',
		Route3: `Route No.3: Xi'an Ancient Capital Cultural Tour`,
		Route4: 'Route No.4',
		TouristDirections: 'Traffic Directions',
		JiHotel: 'Quanji Hotel',
		HarvestInternationalHotel: 'Harvest International Hotel',
		UniversityConstitution: 'About ZUEL',
		CALLFORPAPERS: 'CALL FOR PAPERS',
		VISAANDTRAVEL: 'Travel and Accommodation',
		CONTACTS: 'CONTACTS',
		HotelInformation: 'Hotel Information',
		TouristRoutes: 'Tourist Routes',
		loading: 'loading...',
		Partners: 'WCAH 2024 Partners',
		WCAHTitle: 'NEWS',
		ThematicSpeaker: 'THEMATIC SPEAKER',
		CongressOrganization: 'Congress Organization Committee',
		Accounting: 'Accounting Society of China',
		Academy: 'Academy of Accounting Historians',
		Management: 'Journal of Management World',
		Economic: 'China Financial & Economic Publishing House',
		Shanghai: 'Shanghai Lixin University of Accounting and Finance',
		DownloadApp: 'Download  APP',
		Registration: 'Conference Registration And Fees',
		newsDesc: 'News detail',
	},
	form: {
		login: 'login',
		register: 'register',
		'User-login': 'User login',
		'User-login-tips':
			'Please log in your account to access the background,Your login account is the email you used when registering',
		'account-placeholder': 'Please enter the account number',
		'account-tips': 'Please enter the account number',
		'password-placeholder': 'Please enter password',
		'password-tips': 'Please enter a password of 6-20 digits',
		'log-in': 'Log in',
		'Forget-password-tips': 'Forget your password',
		'Create-account-tips': `Haven't registered an account yet? Create an account`,
		'Password-reset': 'Password reset',
		'Password-reset-tips': 'Enter your email reset password below.',
		'Please-bind-email': 'Please bind email',
		'Please-bind-email-tips': 'Please enter a legal mailbox',
		'send-code': 'Send code',
		'bind-code-placeholder': 'Please bind code',
		'bind-code-tips': 'Please enter the verification code',
		'Reset-password': 'Reset password',
		'Go-log-on': 'Go and log on',
		'confirm-password': 'Please confirm the password',
		'Go-to-login': 'Go to login',
		'Create-account': 'Create an account',
		'Create-account-Welcome': 'Welcome to your registration...',
		registration: 'Welcome to your registration',
		'registration-tips': 'Please enter your real name',
		'password-again': 'Please enter the password again',
		inconsistent: 'The two passwords are inconsistent!',
		'Agree-to': 'Agree to',
		'Service-Agreement': 'Service Agreement',
		'Privacy-policy': 'User Registration Agreement',
		'Create-my-account': 'Create my account',
		'Login-now': 'Have an account already? Login now',
		'old-password-placeholder': 'Please enter your old password',
		'new-password-placeholder': 'Please enter a new password',
		'new-password-agin': 'Please confirm the new password',
		'email-placeholder': 'Please enter email address',
		'Country-placeholder': 'Please select the Country',
	},
	center: {
		personalData: 'Profile',
		PasswordChange: 'PW Reset',
		messageNotification: 'Notice',
		Paper: 'Paper',
		paperUploading: 'paper Uploading',
		account: 'Account',
		phone: 'Phone',
		realName: 'Real Name',
		gender: 'Gender',
		Country: 'Country',
		unit: 'Organization',
		identity: 'identity',
		identity01: 'author',
		identity02: 'Studying for a PhD',
		identity03: 'entourage',
		'identity-placeholder': 'Please select an identity',
		'phone-placeholder': 'Please enter your phone number', //请输入手机号码
		'unit-placeholder': 'Please enter the organization',
		SaveModify: 'Save and Modify',
		enclosure: 'enclosure:',
		UploadDate: 'Upload date:',
		thesisTitle: 'Thesis Title',
		paperPlaceholder: 'Please enter the title of the paper',
		ClickUpload: 'Click Upload',
		uploadTips: `Only one PDF/DOCX/DOC file can be uploaded, and it should not exceed
        500MB`,
		email: 'email',
		'gender-placeholder': 'Please select gender',
		Classified: 'Classified',
		Man: 'Man',
		Woman: 'Woman',
		'pdf-tips-size': 'File size exceeds 500MB',
		'pdf-tips-type': 'The file format is not PDF/DOCX/DOC',
		cancel: 'cancel',
		upload: 'upload',
		'pdf-tips': 'The uploaded file is empty',
		'title-tips': 'Upload title is empty',
		'pdf-tips-file': 'Failed to upload file',
		registrationPayment: 'Registration submission',
		paymentHistory: 'Payment Order',
		myRegistration: 'My Registration',
	},
	pay: {
		destination: 'destination',
		city: 'WuHan',
		checkIn: 'check-in',
		checkOut: 'check-out',
		nights: 'nights',
		checkInTimeTips: 'Start Date',
		checkOutTimeTips: 'End date',
		title1: 'Hotel Selection',
		title2: 'Hotel check-in information',
		hotelIn: 'Check In Hotel',
		dayIn: 'Number of check-in days',
		checkInTime: 'Check-in time',
		checkOutTime: 'Check-out time',
		hotelMoney: 'Hotel expenses',
		title3: 'Registration fee',
		ConferenceName: 'Conference Name',
		identity: 'Account identity',
		payNumber: 'Number of people',
		RegistrationFee: 'Registration fee(CNY)',
		title4: 'Please select payment method',
		toPay: 'To Pay',
		payStore: 'Pay at the store',
		confirmPay: 'confirm Paid',
		payType: 'Payment method',
		AliPay: 'AliPay',
		WeChat: 'WeChat',
		'third-party': 'third-party payment',
		QR: 'Refresh QR code',
		QRExpire: 'QR code has expired',
		paySuccess: 'Payment successful',
		payAmount: 'Payment amount',
		back: 'BACK',
		payError: 'Payment failed',
		tips1: 'Remaining until the order expires',
		tips2: 'Seconds, please complete the payment as soon as possible',
		tips3: 'Scanned code, waiting for payment',
		tips4: 'Please use Alipay to scan',
		tips5: 'Scan QR code payment for payment',
		tips6: 'Please use WeChat to scan',
		oderNum: 'Order No.',
		WaitingPayment: 'Waiting for payment',
		payStatusT: 'paid',
		payStatusF: 'Payment failed',
		payTime: 'time of payment',
		createOrderTime: 'Creation time',
		Registered: 'Registered',
		address: 'Registration address',
		organizer: 'organizer',
		registerTime: 'registration time',
		CreateOrder: 'Create Order',
		confirmPayCompleted: 'Payment completed',
		removeOrder: 'Cancel Order',
		payStatusCancel: 'Order cancelled',
		notes: 'notes',
		position: 'top',
		invoicingBtn: 'Apply for invoicing',
		invoicingPreviewBtn: 'Invoice Preview',
		dialogTitle: 'Apply for invoicing',
		payerType: 'Invoice type',
		payerType0: 'Personal/Non enterprise',
		payerType1: 'Enterprise unit',
		tin: 'Taxpayer identification number',
		payerName: 'Payer name',
		orderNum: 'ID number',
		invoiceTitle: 'Invoice header',
		invoicePhone: 'Phone number',
		invoiceEmail: 'Email',
		dialogbtn1: 'Cancel',
		dialogbtn2: 'Submit',
		tinMsg: 'Please fill in the taxpayer identification number',
		payerNameMsg: 'Please fill in the name of the payer',
		orderNumMsg: 'Please fill in your ID number',
		invoiceTitleMsg: 'Please fill in the invoice header',
		invoicePhoneMsg: 'Please fill in your phone number',
		invoiceEmailMsg: 'Please fill in your email address',
	},
	message: {
		hello: 'hello',
	},
	...enLocale,
}

export default en
