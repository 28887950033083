import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/homePage',
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home'),
        children: [
            {
                path: '/userLogin',
                name: 'userLogin',
                component: () => import('@/views/login/userLogin'),
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('@/views/login/register'),
            },
            {
                path: '/personalData',
                name: 'personalData',
                redirect: '/personalData/data',
                component: () => import('@/views/account/personalData'),
            },
            {
                path: '/personalData/:location',
                name: 'personalDataChild',
                component: () => import('@/views/account/personalData'),
            },
            {
                path: '/reset',
                component: () => import('@/views/login/reset'),
            },
            {
                path: '/homePage',
                name: 'homePage',
                component: () => import('@/views/home/homePage'),
            },
            {
                path: '/newsDetail',
                name: 'newsDetail',
                component: () => import('@/views/home/newsDetail'),
            },
            {
                path: '/university',
                name: 'university',
                component: () => import('@/views/home/university'),
            },
            {
                path: '/Committees',
                name: 'Committees',
                component: () => import('@/views/home/Committees'),
            },
            {
                path: '/ConferenceProgram',
                name: 'ConferenceProgram',
                component: () => import('@/views/home/ConferenceProgram'),
            },
            {
                path: '/papers',
                name: 'papers',
                component: () => import('@/views/home/papers'),
            },
            {
                path: '/travel',
                name: 'travel',
                component: () => import('@/views/home/travel'),
            },
            {
                path: '/hotel',
                name: 'Hotel',
                component: () => import('@/views/home/hotel'),
            },
            {
                path: '/routeLine',
                name: 'RouteLine',
                component: () => import('@/views/home/routeLine'),
            },

            //{
            //    path: '/partner',
            //    name: 'partner',
            //    component: () => import('@/views/home/partner')
            //},
            {
                path: '/contactWay',
                name: 'contactWay',
                component: () => import('@/views/home/contactWay'),
            },
            {
                path: '/Registration',
                name: 'Registration',
                component: () => import('@/views/home/Registration'),
            },
        ],
    },
    {
        path: '/aliPay',
        name: 'AliPay',
        component: () => import('@/views/payment/AliPay'),
    },
    {
        path: '/weixinPay',
        name: 'WeixinPay',
        component: () => import('@/views/payment/WeixinPay'),
    },
]

const router = new VueRouter({
    routes,
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch((err) => err)
}

export default router
