import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/common.scss'


import qs from 'qs'
import HttpProxy from './api/https'
import i18n from './i18n/i18n'

Vue.prototype.$HttpProxy = HttpProxy
Vue.prototype.$QS = qs

// 国际化
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
})
//Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
